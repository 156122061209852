
.dropdown-item {
  display: flex;
  font-weight: 600;
  font-size: $font-size-xs;
  padding-left: $spacer*5;
  padding-right: $spacer*5;
  flex-direction: row;
  align-items: center;
  line-height: 28px;
  &:hover {
    background-color: $dropdown-link-hover-bg;
  }
  &:active {
    background-color: $dropdown-link-active-bg;
    color: $dropdown-link-active-text;
  }
  &__icon {
    line-height: 1;
    display: inline-block;
    color: $body-secondary-color;
    font-size: $font-size-xs;
  }
  &--danger {
    color: $danger !important;
    &:hover {
      color: $danger !important;
      background-color: $dropdown-item-danger-active-bg !important;
    }
  }
}

.dropdown-menu.right {
  right: 0;
}


.dropdown-menu.auto {
  min-width: auto;
}
