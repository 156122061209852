
$light100: white;
$light200: #F6F6F7;
$light300: #EDEDEF;
$light400: #E4E4E6;
$light500: #DBDBDE;
$light600: #D2D2D6;
$light700: #C5C5C7;
$light800: #8B8B8F;

$dark100: #D2D2D2;
$dark200: #A5A5A5;
$dark300: #777779;
$dark400: #4A4A4C;
$dark500: #1D1D1F;

$body-bg: $light200;

$primary: #BC2CC0;
$primary400: #C956CD;
$primary300: #D780D9;
$primary200: #E4ABE6;
$primary100: #F2D5F2;
$primaryAlpha8: rgba($primary,0.08);
$primaryAlpha16: rgba($primary,0.16);

$secondary: #3E2155;
$secondary400: #654D77;
$secondary300: #8B7A99;
$secondary200: #B2A6BB;
$secondary100: #D8D3DD;
$secondaryAlpha8: rgba($secondary,0.08);
$secondaryAlpha16: rgba($secondary,0.16);

$action: #1977EC;
$action400: #4792F0;
$action300: #75ADF4;
$action200: #A3C9F7;
$action100: #D1E4FB;
$actionAlpha8: rgba($action, 0.08);
$actionAlpha16: rgba($action, 0.16);

$purple: #A42F89;

$warning: #FECD34;
$warning400: #FED75D;
$warning700: #fec321;
$warning900: #fdb51b;

$info: #3EB8E5;
$info400: #65C6EA;
$info400: #65C6EA;
$info300: #8BD4EF;
$info200: #B2E3F5;
$info100: #D8F1FA;

$success: #30DB5B;
$success400: #59E27C;
$successAlpha16: rgba($success, 0.16);

$danger: #FF453A;
$danger400: #fb5349;
$dropdown-item-danger-active-bg: rgba($danger,0.08);

$hr-border-color: $light400;
$hr-opacity: 1;

$link-color: $action;
$link-hover-color: $action400;
$link-color-dark: $action;
$link-hover-color-dark: $action400;

$input-border-color: $light700;
$text-muted: $dark200;
// Then add them to your custom theme-colors map, together with any additional colors you might nee
//Products
$collect: #8530F7;
$collect400: #9D59F9;
$forward: #FF4F7B;
$forward400: #FF7295;
$scan: #3EB8E5;
$phone: #736FDB;
$scan400: #65C6EA;


$colors: (
        card: white,

        body: $body-color,
        primary: $primary,
        primary100: $primary100,
        primary200: $primary200,
        primary300: $primary300,
        primary400: $primary400,
        primaryAlpha8: $primaryAlpha8,
        primaryAlpha16: $primaryAlpha16,

        secondary: $secondary,
        secondary400: $secondary400,
        secondaryAlpha8: $secondaryAlpha8,
        secondaryAlpha16: $secondaryAlpha16,

        info: $info,
        action: $action,
        action100: $action100,
        action200: $action200,

        warning: $warning,
        warning400: $warning400,
        warning700: $warning700,
        warning900: $warning900,

        success: $success,
        success400: $success400,

        danger: $danger,
        danger400: $danger400,

        white: white,
        light: $light500,
        light100: $light100,
        light200: $light200,
        light300: $light300,
        light400: $light400,
        light600: $light600,
        light800: $light800,
        dark: $dark500,
        dark100: $dark100,
        dark200: $dark200,
        dark300: $dark300,
        dark400: $dark400,

        collect: $collect,
        forward: $forward,
        scan: $scan,
);



$theme-colors: $colors;
$body-secondary-color: $dark300; //text-muted
$body-color: $dark500;
$body-color-dark: $light300;

$btn-primary-color: white;
$btn-primary-hover-background: $primary400;
$btn-primary-active-background: $primary;
$btn-secondary-color: white;
$btn-secondary-hover-background: $secondary400;
$btn-secondary-active-background: $secondary;

$switch-bg: $success;
$switch-bg-off: $light600;

$badge-color:white;
$form-check-input-bg: $action;
$form-check-input-border-color: $input-border-color;
$form-check-input-checked-border-color: $action;
$form-check-focus-border-color: $action;
$form-check-input-checked-bg-color:$action;
$form-check-input-focus-border:$action;
$form-check-input-focus-bg-color:$actionAlpha16;
$form-check-input-checked-focus-bg-color:$successAlpha16;
$form-check-input-focus-box-shadow: none;
$form-check-radio-checked-bg-color: $action;
$form-check-input-checked-color: $success;

$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg width="22" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7566 6.36121C15.0965 6.82168 15.0782 7.54493 14.7155 7.97662L9.91555 13.6909C9.56936 14.103 9.03065 14.103 8.68445 13.6909L6.28446 10.8338C5.92183 10.4021 5.90346 9.67883 6.24342 9.21836C6.58338 8.75788 7.15293 8.73455 7.51555 9.16625L9.3 11.2906L13.4845 6.3091C13.8471 5.87741 14.4166 5.90074 14.7566 6.36121Z" fill="#{$form-check-input-checked-color}"/></svg>');
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4993 21.0846C6.20647 21.0846 1.91602 16.7942 1.91602 11.5013C1.91602 6.20843 6.20647 1.91797 11.4993 1.91797C16.7922 1.91797 21.0827 6.20843 21.0827 11.5013C21.0827 16.7942 16.7922 21.0846 11.4993 21.0846Z" fill="#1977EC"/><path d="M9.81621 15.7994L5.75 11.7332L7.10508 10.3781L9.81621 13.0893L15.2365 7.66797L16.5926 9.02305L9.81621 15.7994Z" fill="white"/></svg>');

$input-focus-border-color: $primary;
$input-bg: transparent;
$input-bg-autofill: #E7F0FE;
$dropdown-link-hover-bg: $primaryAlpha8;
$dropdown-link-active-bg: $primary;
$dropdown-link-active-text: white;

$th-bg: white;
$th-color: $dark300;
$th-border: $input-border-color;

$border-color: $light500;
$input-icon-color: $body-secondary-color;


$aside-bg:$light200;
$aside-section-bg:black;

$aside-item-active:$action;
$aside-text:white;
$aside-text-inactive:$body-color;
$aside-item-hover-bg:$light300;
$aside-item-hover-color: $body-color;
$navbar-bg: $light200;

$tooltip-bg: $dark500;
$tooltip-text: white;


$chip-color: $light700;
$chip-bg: $light400;
$chip-hover-bg: $light400;

$dropdown-bg: $light100;
$shadow-1: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
$shadow-2: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
$shadow-3: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
$shadow-4: 0px 8px 35px 0px rgba(0, 0, 0, 0.16);

@each $name, $hex in $theme-colors {
  .hover-bg-#{$name}:hover{ //workaround for lack of text-Theme support in dark mode
    background-color: $hex !important;
    &.bg-opacity-10 {
      background-color: rgba($hex,0.1) !important;
    }
  }
}
@each $name, $hex in $theme-colors {
  .fill-#{$name} { //workaround for lack of text-Theme support in dark mode
    fill: $hex !important;
    path {
      fill: $hex !important;
    }
  }
}
.hover-primary:hover, .hover-target:hover>.hover-primary {
  color: $primary!important;
}
@import "dark-mode";
