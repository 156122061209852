
$btn-padding-y:0;
$btn-padding-x:$spacer*4;
$btn-font-weight: 400;
$btn-padding-y-sm: 0;
$btn-padding-x-sm:$spacer*4;
$btn-line-height: 44px;
$btn-line-height-sm: 38px;
$btn-line-height-xs: 22px;
$btn-line-height-lg: 46px;

$input-line-height: $btn-line-height;
$input-padding-y:0;
$input-focus-box-shadow: none;

$form-label-font-weight: $font-weight-semibold;

$form-check-input-width: 20px;
$form-check-input-height: 20px;

$btn-disabled-opacity: 0.5;
$input-disabled-opacity: 0.75;

$switch-width: 40px;
$switch-height: 24px;

$td-padding: $spacer*4 $spacer*3;
$th-padding: $spacer*2 $spacer*2;

$dropdown-item-padding-x: $spacer*2;

$switch-disabled-opacity:$input-disabled-opacity;
$form-check-input-disabled-opacity: $input-disabled-opacity;
$form-check-label-disabled-opacity: $input-disabled-opacity;

$link-decoration: none;
$link-hover-decoration: none;
$link-font-weight: $font-weight-semibold;
$btn-border-radius: $border-radius-xl;
$btn-border-radius-sm: $border-radius-lg;
$btn-border-radius-xs: $border-radius-lg;
a {
  font-weight:$link-font-weight;
}

@include color-mode(dark) {
  $input-disabled-opacity: 0.25;
  $switch-disabled-opacity:$input-disabled-opacity;
  $form-check-input-disabled-opacity: $input-disabled-opacity;
  $form-check-label-disabled-opacity: $input-disabled-opacity;
}
