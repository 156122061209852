.page-container {
  max-width: 1360px;
  margin: 0;
  padding-top: 0;
  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-top: $spacer * 4;
  }
  h1 {
    line-height: 40px;
  }
  padding-bottom: $spacer*10;
}

.dropdown-menu {
  border:none;
  overflow: hidden;
  border-radius: $border-radius-lg;
  box-shadow: $shadow-3;
}

.bg-info.opacity-10 { // override info message
  border-color: $action100 !important;
  background-color: $action100 !important;
}



@import "~bootstrap/scss/tables";
//Polyfill colours
$grey5: $hr-border-color;

$black1: $dark;
.color-white {
  color: white;
}



.tide-pink {
  color: #E6167E;
}
.featured-offer-card {
  h3 {
    font-size: 18px;
    line-height: 22px;
    padding-right: 99px;
  }
  background: linear-gradient(180deg, #2634B5 0%, #070843 60.33%, #3E3F97 68.42%, #F7F7F7 87%);

  &.crisp{
    background:#1a72f5 !important;
  }

  .character {
    bottom: 0px;
    width: 160px;
    position: absolute;
    right: 0;
  }
}

.ad-brand{
  height:60px;
}


//end of polyfill

.mp-hero-container {
  height: 690px;
  position: absolute;
  right: 225px;
  top: 20px;
  width: 822px;
}
.offer-detail-brand-logo {
  max-width: 25vw;
}
.offer-card {
  .color-grey5 {
    color: $grey5;
  }
  .lead {
    font-size: 1.25rem;
  }
  border-color: $grey5 !important;
  width: 444px;
  border-radius: 16px !important;
  .product-container {
    height: 208px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    overflow: hidden;
    align-items: center;

    .offer-image {
      max-width: 100%;
      object-fit: contain;
    }
  }
  .border-color {
    border-color: $grey5 !important;
  }
  .title-container {
    min-height: 130px;
    padding: 16px 20px;
    >*{
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.2;
    }
  }
  .description-container {
    height: 170px;
    padding: 16px 20px;
    >* {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }

  }
  .btn-container {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    height: 99px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .redeem-btn {
    border-radius: 6px;
    height: 51px;
    width: 100%;
    background: $black1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border-color: transparent;
    text-decoration: none;
    &:hover {
      border-color: $primary;
    }
  }
}
.category-container {
  display: flex;
  flex-wrap: wrap;
  & > .chip {
    border: 2px solid $black1;
    border-radius: 22px;
    padding: 4px 20px;
    display: flex;
    background-color: transparent !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.marketplace-banner {
  .subtext {
    line-height: 22px;
    font-size: 19px;
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
  .hero-image {
    top: 0px;
    left: 0px;
    width: 243px;
    height: 81px;
    position: absolute;
  }
}
.marketplace-footer {
  .footer-btn {
    width: 114px;
    height: 37px;
    border: 2px solid #ffffff;
    border-radius: 22px;
    background-color: transparent;
  }
  .subscribe-container {
    input {
      flex: 1;
      min-width: 190px;
    }

    button {
      flex: 0;
      background-color: $primary;
      color: $white;
      height: 42.5px;
      font-size: 13px;
      margin-left: -4px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-width: 0px;
      z-index: 1;
    }
  }

  @media (min-width: 767px) and (max-width: 1479px) {
    .subscribe-container {
      button {
        margin-left: 0;
        margin-top: 10px;
        border-radius: 5px;
        margin-left: 0px;
        width: 100%;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .offer-detail-brand-logo {
    max-width: 60vw;
  }
}

.offer-main-container {
  .offer-container {
    top: -104px
  }
  table {
    @extend .table;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 28px;
  }
  main {
    > *:last-child {
      padding-bottom: 5em !important;
    }
  }
  .rounded-box {
    border-radius: 17px;
    box-shadow: 0px 6px 6px #0000001a;
  }
  .divider {
    border: 1px solid $grey5;
    width: 100%;
  }
  .carousel-container {
    padding-left: 22%;
  }
}

@include media-breakpoint-down(sm) {
  .offer-card  {
    .product-container {
      height: 150px;
    }
    .title-container {
      height: auto;
    }
  }
}


@include media-breakpoint-down(lg) {
  .offer-main-container {
    .offer-container {
      top: -50px
    }
  }
}

.btn {
  text-wrap: nowrap;
  min-height: 40px;
  &-carousel {
    padding:0;
    background-color: $light700 !important;
    width: 8px;
    height: 8px;
    max-height: 8px;
    min-height: 8px;
    box-shadow: $shadow-3;
    &:hover {
      background-color: darken($light700, 10) !important;
    }
    &:disabled {
      background-color: darken($light700, 20) !important;
    }

    &--active {
      background-color: $primary !important;
    }
  }
}

.btn-money {
  width: 95px;
}
.login-form {
  min-width: 450px;
  max-width: 450px;
}
.sticky-actions-container {
  max-width: 1240px;
  .shadow-2 {
    min-height: 264px;
  }
}
.sticky-buttons {
  button {
    min-width: 128px;
  }
}
