.btn {
  text-wrap: nowrap;
  &.btn-primary {
    color: $btn-primary-color !important;
    &:hover {
      color: $btn-primary-color;
      background-color: $btn-primary-hover-background;
      border-color: $btn-primary-hover-background;
    }
    &:active,&:focus,&:focus-visible {
      background-color: $btn-primary-active-background;
      border-color: $btn-primary-active-background;
    }
  }
  &.btn-danger {
    color: white;
    &:hover,&:active {
      color: white;
    }
  }
  &.btn-secondary {
    color: $body-color !important;
    border-color: $body-color;
    border-width: 1.5px;
    line-height: $btn-line-height - 3;
    background-color: transparent !important;
    &:hover {
      color: white !important;
      background-color: $body-color !important;
    }
  }
  line-height: $btn-line-height - 1.5;
  height: $btn-line-height;
  &.btn-sm {
    border-radius: $btn-border-radius-sm;
    height: $btn-line-height-sm+1.5;
  }
  &.btn-xs {
    border-radius: $btn-border-radius-xs;
    line-height: $btn-line-height-xs - 2;
    height: $btn-line-height-xs;
    min-height: $btn-line-height-xs+1.5;
  }
  &.btn-lg {
    padding-top: 0;
    padding-bottom: 0;
    line-height: $btn-line-height-lg+1;
    height: $btn-line-height-lg+1.5;
  }
  &.btn-outline-light {
    border-color: white;
    color: white;
    &:hover {
      background-color: transparent;
    }
  }
  &.btn-outline-primary {
    &:hover {
      background-color: $primaryAlpha8;
    }
    &:active {
      background-color: $primaryAlpha16 !important;
      color:$primary !important;
    }
    &:hover, &:active {
      color: $primary;
    }
  }

  &.btn-outline-secondary {
    &:hover {
      background-color: $primaryAlpha8;
    }
    &:active {
      background-color: $primaryAlpha16 !important;
      color:$primary !important;
    }
    &:hover, &:active {
      color: $primary;
    }
  }

  &.btn-icon {
    padding: 0;
    min-width: $btn-line-height+2;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-sm {
      min-width: $btn-line-height-sm+2;
    }
    &.btn-lg {
      min-width: $btn-line-height-lg+2;
    }
    &.btn-xs {
      min-width: $btn-line-height-xs+2;
    }
  }
}
.btn-pagination {
  line-height: normal !important;
  height: $btn-line-height-xs+2;
  min-width: $btn-line-height-xs+2;
  font-size: $font-size-base !important;
  border-radius: $border-radius-sm;
  display: flex;
  justify-content: center;
  align-items: center;
}
