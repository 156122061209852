@import "../mixins/custom-scrollbar";

$aside-width: 228px;
$aside-width-collapsed: 80px;
.aside {
  background-color: $aside-bg;
  font-size: $font-size-base;
.logo {
  opacity: 0;
}
  .dropdown {
    background-color: $aside-bg;
  }


  &__section {
    background-color: $aside-section-bg;
  }

  &__item {
    height: 34px;

    .icon-container {
      width: 34px;
      flex-shrink: 0; /* Prevent shrinking */
      flex-grow: 0; /* Prevent growing */
      text-align: center;
      display: block;
    }
    padding-right: 0!important;
    padding-left: 0!important;
    align-items: center;
    width: 34px;
    .content {
      opacity: 0
    }

    .fa {
      &:before, &:after {
        width: 34px;
        display: block;
      }
      font-size: 18px;
      display: inline-block;
      text-align: center;
    }

    user-select: none;
    color: $aside-text-inactive;

    &:hover {
      background-color: $aside-item-hover-bg;
      color: $aside-item-hover-color;

      .fa {
        opacity: 1;
      }
    }

    &--active {
      cursor: default;
      background-color: $aside-item-active !important;
      color: $aside-text !important;

      .fa {
      }
    }
  }

  .active .aside__item {
    @extend .aside__item--active;
    svg {
      path, circle {
        fill: white;
        &.stroke {
          fill: transparent;
          stroke: white;
        }
      }
    }
  }
}

.aside-container.expanded {
  width: $aside-width;
  .logo {
    opacity: 1;
  }
  .aside__item {
    flex-wrap: nowrap;
    .content {
      opacity: 1
    }

    width: 200px;
  }
}

.aside-container {
  overflow: hidden;
  width: $aside-width-collapsed;
}

.aside-fixed {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.aside-offset {
  margin-left: $aside-width;
}
.aside-collapsed {
  .aside-offset {
    margin-left: $aside-width-collapsed;
  }
}

@media (max-width: 767px) {
  .aside-fixed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    width: 100% !important;
    .animated {
      opacity: 1 !important;
    }

    .aside {
      margin-top: -1px;
      height: calc(100vh - 70px) !important;
      &__item {
        width: 100% !important;
      }
    }

    &.open {
      height: 100vh;
      bottom: 0;
    }
  }

  .aside-offset {
    margin-top: 70px;
    margin-left: 0;
  }
}

body.overflow-hidden .aside-offset {
  overflow: hidden !important;
}
