table {
  width: 100%;
}
td {
  padding: $td-padding;
}
th {
  padding: $th-padding;
  background-color: $th-bg;
}
tr {
  vertical-align: baseline;
  border-bottom: 1px solid $hr-border-color !important;
  &:last-child {
    border-bottom: none !important;
  }
}
thead {
  border-bottom: 1px solid $hr-border-color !important;
  font-size: $th-font-size;
}
th {
  color: $th-color;
  padding: 0 $spacer*2 $spacer*4 $spacer*2;
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
  &:first-child, &.first {
    border-radius: 12px 0 0 12px;
    padding-left: $spacer * 3;
  }
  &:last-child {
    border-radius: 0 $border-radius $border-radius 0;
    padding-right: $spacer * 5;
  }
}
thead {
  border-bottom: 1px solid $input-border-color !important;
}
.fitwidth {
  width: 1px;
  white-space: nowrap;
}
